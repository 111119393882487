<template>
  <div>
    <div class="offcanvas offcanvas-start" style="width:85%" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header" :style="skin.side_bar.header + skin.side_bar.header_text">
            <ul class="nav p-1 mx-1 me-1" :style="nav_activeCss">
                <li 
                    :id="'pills-menuSport'"
                    data-bs-toggle="pill"
                    :data-bs-target="'#pills-menuSport-tab'"
                    role="tab"
                    :aria-controls="'pills-menuSport'"
                    aria-selected="true"
                    style="cursor:pointer"
                    v-on:click="active('pills-menuSport')"
                    class="nav_active1 item-menu nav-item">
                    <span class="m-2">{{translate("sports_menu").toUpperCase()}}</span> 
                </li>
                <li 
                    :id="'pills-navegacion'"
                    data-bs-toggle="pill"
                    :data-bs-target="'#pills-navegacion-tab'"
                    role="tab"
                    :aria-controls="'pills-navegacion'"
                    aria-selected="true"
                    style="cursor:pointer"
                    v-on:click="active('pills-navegacion')"
                    class="item-menu nav-item">
                    <span class="m-2">{{translate("navigation").toUpperCase()}}</span>
                </li>
            </ul>
            <button id="closeSideBar" type="button" class="btn text-reset" data-bs-dismiss="offcanvas" aria-label="Close">
                <span style="color:white;font-size:large"><i class="fas fa-times"></i></span>
            </button>
        </div>
        <div class="offcanvas-body p-0" :style="skin.side_bar.body">
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" 
                    :style="skin.fondo0"
                    id="pills-menuSport-tab"
                    role="tabpanel"
                    aria-labelledby="pills-menuSport"
                >
                    <language-selector
                        v-bind:skin="skin"
                        v-bind:language="language"
                    />
                    <main-search
                        v-bind:skin="skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                    />
                    <div v-if="skin.qbet.booking_enabled" class="d-flex p-2 m-1" :style="skin.side_bar.body2" style="align-items: stretch;">
                        <input v-model="booking_code" class="form-control form-control-sm border" type="number" :placeholder="translate('reservation_code')" style="font-size:small;"/>
                        <button class="btn" :style="skin.side_bar.booking_verify" type="button" @click="check_booking">{{translate("verify")}}</button>
                    </div>
                    <main-ligas
                    v-if="prematchTarget==1"
                    v-bind:skin="skin"
                    v-bind:language="language"
                    v-bind:translation="translation"
                    />
                    <main-ligas-2
                        v-else
                        v-bind:skin="skin"
                        v-bind:language="language"
                        v-bind:translation="translation"
                    />
                    <div class="m-1 mb-2" :style="skin.side_bar.body2" style="overflow:hidden">
                        <div class="p-2" :style="skin.side_bar.header2">{{translate("live_menu")}}</div>
                        <eventoDeporteLive
                            v-for="(deporte, idx) in sports"
                            v-bind:deporte="deporte"
                            v-bind:Skin="skin"
                            v-bind:key="idx"
                            v-bind:language="language"
                            v-bind:translation="translation"
                            >
                        </eventoDeporteLive>
                    </div>
                    <div class="m-1 mb-2" :style="skin.side_bar.body2" style="overflow:hidden">
                        <div class="p-2" :style="skin.side_bar.header2">{{translate("prematch_menu")}}</div>
                        <timeLine
                            v-if="prematchTarget==1"  
                            v-bind:skin="skin"
                            v-bind:language="language"
                            v-bind:translation="translation"/>
                        <div v-if="prematchTarget==0">
                            <div class="text-center p-3">
                                <span :style="skin.menu_prematch.calendar_text">{{translate("schedule")}}</span>
                                <hr class="my-1 border border-1 border-white"/>
                                <div :style="skin.menu_prematch.calendar + 'font-size:small'" class="row">
                                    <div @click="select_time('1hh')" id="1hh" class="border border-1 border-white col col-2 p-0 m-0 time" :style="time2 == '1hh' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">1HH</div>
                                    <div @click="select_time('3hh')" id="3hh" class="border border-1 border-white col col-2 p-0 m-0 time" :style="time2 == '3hh' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">3HH</div>
                                    <div @click="select_time('hoy')" id="hoy" class="border border-1 border-white col col-2 p-0 m-0 time" :style="time2 == 'hoy' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">{{translate("today")}}</div>
                                    <div @click="select_time('hoy1')" id="hoy1" class="border border-1 border-white col col-3 p-0 m-0 time" :style="time2 == 'hoy1' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">{{translate("tomorrow_sportium")}}</div>
                                    <div @click="select_time('todos')" id="todos" class="border border-1 border-white col col-3 p-0 m-0 time" :style="time2 == 'todos' ? skin.menu_prematch.calendar_selected : ''" style="cursor:pointer">{{translate("all")}}</div>
                                </div>
                            </div>
                            <div v-if="!timeLoading">
                                <eventoDeportePrematch
                                v-for="(deporte, idx) in sports_prematch"
                                v-bind:deporte="deporte"
                                v-bind:Skin="skin"
                                v-bind:key="idx"
                                v-bind:language="language"
                                v-bind:translation="translation"
                                >
                                </eventoDeportePrematch>  
                            </div>
                            <div v-else class="text-center py-5">
                                <div class="spinner-border" :style="skin.loader" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="tab-pane fade p-2"
                    id="pills-navegacion-tab"
                    role="tabpanel"
                    aria-labelledby="pills-navegacion"
                >
                    <div v-if="skin.front_lobby.enabled" @click="change_view('lobby')" class="p-2">
                        <div class="d-flex">
                            <div class="icon_cup" style="width:18px;height:18px"></div>
                            &nbsp;<span style="color:white">{{translate("home")}}</span>
                        </div>
                    </div>
                    <div v-else @click="change_view('main')" class="p-2">
                        <div class="d-flex">
                            <div class="icon_cup" style="width:18px;height:18px"></div>
                            &nbsp;<span :style="skin.side_bar.navigation_text">{{translate("home")}}</span>
                        </div>
                    </div>
                    <div @click="change_view('main')" class="p-2">
                        <div class="d-flex">
                            <div class="icon_cup" style="width:18px;height:18px"></div>
                            &nbsp;<span :style="skin.side_bar.navigation_text">{{translate("sports_forecasts")}}</span>
                        </div>
                    </div>
                    <div @click="change_view('live')" class="p-2">
                        <div class="d-flex">
                            <div class="icon_cup" style="width:18px;height:18px"></div>
                            &nbsp;<span :style="skin.side_bar.navigation_text">{{translate("live_forecasts")}}</span>
                        </div>
                    </div>
                    <div v-if="skin.poker" @click="user.token != '' ? change_view('poker') : login()" class="p-2">
                        <div class="d-flex">
                            <div class="icon_poker" style="width:18px;height:18px"></div>
                            &nbsp;<span :style="skin.side_bar.navigation_text">POKER</span>
                        </div>
                    </div>
                    <div v-if="skin.casinoN" @click="user.token != '' ? change_view('casino') : login()" class="p-2">
                        <div class="d-flex">
                            <div class="icon_casino" style="width:18px;height:18px"></div>
                            &nbsp;<span :style="skin.side_bar.navigation_text">{{translate("casino")}}</span>
                        </div>
                    </div>
                    <div v-if="skin.casino_live" @click="user.token != '' ? change_view('casino_live') : login()" class="p-2">
                        <div class="d-flex">
                            <div class="icon_casino" style="width:18px;height:18px"></div>
                            &nbsp;<span :style="skin.side_bar.navigation_text">{{translate("live_casino")}}</span>
                        </div>
                    </div>
                    <div v-if="skin.hipicas" @click="user.token != '' ? change_view('hipicas') : login()" class="p-2">
                        <div class="d-flex">
                            <div class="icon_casino" style="width:18px;height:18px"></div>
                            &nbsp;<span :style="skin.side_bar.navigation_text">{{translate("horse_riding")}}</span>
                        </div>
                    </div>
                    <div @click="change_view('results')" class="p-2">
                        <div class="d-flex">
                            <div class="icon_cup" style="width:18px;height:18px"></div>
                            &nbsp;<span :style="skin.side_bar.navigation_text">{{translate("results")}}</span>
                        </div>
                    </div>
                    <div @click="terms" class="p-2">
                        <span><i class="far fa-list-alt me-1"></i>{{translate("terms_and_conditions").toUpperCase()}}</span>
                    </div>
                </div>
            </div>
        </div>  
    </div>
  </div>
</template>

<script>
import mainSearch from '../main_data/main_search.vue';
import eventoDeporteLive from './live/Evento_deporte_live.vue';
import timeLine from './prematch1/Time_line_prematch.vue';
import mainLigas from '../main_data/main_ligas.vue';
import mainLigas2 from '../main_data/main_ligas2.vue';
import eventoDeportePrematch from '../main_menu/prematch2/Evento_deporte_prematch2.vue';
import router from '../../../../router/index';
import languageSelector from '../../header/language';
export default {
    name: 'Sidebar',
    components: {
        eventoDeporteLive,
        timeLine,
        mainSearch,
        mainLigas,
        mainLigas2,
        eventoDeportePrematch,
        languageSelector
    },
    props:['translation','language'],
    data(){
        return {
        booking_code:'',
        }
    },
    computed:{
        favoritos(){
            return this.$store.getters['live/getFavorites']; 
        },
        sports(){
           return this.$store.getters['live/getSports'];
        },
        skin(){
            return this.$store.getters['getCurrentSkin'];
        },
        user(){
            return this.$store.getters['getUserData'];
        },
        prematchTarget(){
            return this.$store.getters['getPrematchTarget'];
        },
        time2(){
            return this.$store.getters['prematch_2/getTime'];
        },
        timeLoading(){
            return this.$store.getters['prematch_2/getTimeLoading'];
        },
        sports_prematch(){
            return  this.$store.getters['prematch_2/getSports'];
        },
        nav_activeCss(){
            return {
            '--bar':this.skin.side_bar.nav_active_bar,
            '--text':this.skin.side_bar.nav_active_text,
            }
        },
        config(){
            return this.$store.getters['getConfig'];
        },
    },
    methods:{
        translate(text) {
            if (this.$props.translation[text]) {
                return this.$props.translation[text][this.$props.language];
            }
            return text;
        },
        active: function (id) {
            let items = document.getElementsByClassName("item-menu");
            [...items].forEach(item=>{
                item.classList.remove('nav_active1');
                item.classList.remove('active');
            });
            document.getElementById(id).classList.add('nav_active1');
            document.getElementById(id).classList.add('active');
        },
        check_booking(){
            if(this.booking_code!=''){
                this.$store.dispatch('getBooking',this.booking_code);
            }
            else{
                this.alert.message=this.translate('Ingresa un código de reserva válido');
                this.alert.type='danger';
                this.alert.active=true;
                setTimeout(this.alert_off,5000);
            }
        },
        change_view(view){
            document.getElementById('closeSideBar').click();
            if(view == "casino_live"){
                window.postMessage({view: 'casino_live'},'*');
            }
            else if(view == "hipicas"){
                // let url = this.config.SkinCConfig.racing.horse.url+'?tokenUser='+this.user.token+this.user.site_id+'&tokenClient=72b0fc93-fdd1-4c08-b1fd-0be81726a5d2&lang=es&cashierUrl='+this.user.domain+'&homeUrl='+this.user.domain;
                // window.open(url,'_blank');
                window.postMessage({view: 'hipicas'},'*');
            }
            else if(view == "live"){
                if(this.user.token==''){
                    router.push({ name: 'LiveMainEcuabets', query: { token: this.user.token+this.user.site_id} })
                }
                else{
                    router.push({ name: 'Live_ecuabets', query: { token: this.user.token+this.user.site_id} })
                }
            }
            else{
                this.$store.dispatch('setLobbyView',view);
                this.$store.dispatch('setView',view);
                window.postMessage({view: view},'*');
            }
        },
        login(){
            document.getElementById('closeSideBar').click();
            document.getElementById('login_btn').click();
        },
        select_time(time){
            let timer = {
                "1hh":{
                    "@now": {
                        "@gte": 0,
                        "@lt": 3600
                    }
                },
                "3hh":{
                    "@now": {
                        "@gte": 0,
                        "@lt": 10800
                    }
                },
                "hoy":{
                    "@now": {
                        "@gte": 0,
                        "@lt": 57600
                    }
                },
                "hoy1":{
                    "@now": {
                        "@gte": 57600,
                        "@lt": 144000
                    }
                },
                "todos":{},
            }
            this.$store.dispatch('prematch_2/select_time',{name:time,timer:timer[time]});
        },
        terms(){
            let url = "";
            let lang = this.$props.language=='es'?'spa':(this.$props.language=='en'?'eng':(this.$props.language=='pt'?'pt-br':'spa'));
            if(this.user.prefix=="external"){
                url = "https://docs.betsw3.xyz/terms/"+lang+"/?site="+this.user.site.toUpperCase()+"&link="+this.user.site;
            }
            else{
                let domain_formated = this.user.domain.split('//')[1]; 
                //let domain_formated = "admin.championred.bet";
                let domain_name1 = domain_formated.split('.')[1].toUpperCase();
                let domain_name2 = domain_formated.split('.')[1]+'.'+domain_formated.split('.')[2];
                url = "https://docs.betsw3.xyz/terms/"+lang+"/?site="+domain_name1+"&link="+domain_name2;
            }
            
            window.open(url, '_blank').focus();
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input-group2 {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
}
.nav_active1{
  color:var(--text);
  border-bottom:var(--bar);
}
</style>
